<template>

    <b-card-code
      no-body
    >
      
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
    
          <b-overlay
            :show="isLoading"
            rounded="sm"
          >
          
            
  
            <b-table 
              responsive
              :items="items" 
              :fields="tableColumns" 
              striped 
              class="position-relative"
            >
  
            <template #cell(id)="data">
                {{data.index + 1}}
            </template>
  
  
            <template #cell(time)="data" >
                {{data.value | moment('YY-MM-DD HH:mm:ss')}}
            </template>
  
            
  
  
            <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  
                >
  
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item> -->
  
                  <b-dropdown-item @click="deleteData(data.item.id)" >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">حذف</span>
                  </b-dropdown-item>
  
  
                </b-dropdown>
              </template>
  
            </b-table>
  
            <div class="demo-spacing-0">
          <b-pagination
            :value="1"
            
            :total-rows="pagination_total"
            :per-page="pagination_per_page"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @input="(value)=>getData(this.$route.params.id,value)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
          
  
          </b-overlay>
        <!-- </div> -->
    </b-card-code>
  </template>
  
  
  <script>
  import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton,BCardBody, BPagination  } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 
  
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import { onMounted } from 'vue'
  import DataService  from './data/services'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  
  
  export default {
    components: {
      BTable,
      BCard,
      BCardText,
      BAvatar,
      BOverlay,
      BBadge,
      BFormSelect,
      BDropdownItem,
      BDropdown,
      BButton,
      BCardBody,
      ToastificationContent,
      BCardCode,
      BPagination,
    },
  
    data() {
      return {
        items: [],
        sidebarData: {},
        isAddNewSidebarActive: false,
        isLoading: true,
        adminType: [],
        pagination_per_page: 20,
        pagination_total: 100,
  
        currentPage: 1,
        page:1,
        perPage: 1,
        rows: 200,
  
        tableColumns: [
          { key: 'id',label: 'الرقم', sortable: true },
          { key: 'user.name', label: ' العميل', sortable: true },
          { key: 'body', label: 'النص', sortable: true },
          { key: 'rating', label: 'التقييم', sortable: true },
          { key: 'time', label: 'التاريخ'},
          
          { key: 'actions',label: ' اﻹجراءات' },
        ],
        
      }
    },
    methods: {
      getData(providerId,page){
        this.isLoading = true;
        
        DataService.reviewData(this.$route.params.id,page)
        .then(response => {
          this.getUserCount()
          this.isLoading = false
          // this.items = response.data.data
          this.items = response.data
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'success',
              },
            })
          })
      },
      getUserCount() {
        DataService.getUserCount()
                    .then(response => {
                      this.pagination_per_page = 20;
                      this.pagination_total = 40;
                      // this.pagination_total = response.data.items;
                    })
      },
      deleteData(id){
        this.isLoading = true;
        DataService.deleteReview(id)
        .then(response => {
          this.isLoading = false
          this.items = response.data.data
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'success',
              },
            })
        })
      },
      
      refreshData(newData){
        this.items = newData
      }
    },
    mounted() {
      this.getData(this.$route.params.id,this.currentPage)
      // this.getType()
    },
    
  }
  </script>